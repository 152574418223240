import React, { lazy } from "react";
// Auth
const Register = lazy(() => import("./Auth/Register"));
const Login = lazy(() => import("./Auth/Login"));
const Profile = lazy(() => import("./Auth/Profile"));
const Setting = lazy(() => import("./Auth/Setting"));

// chat
const Chat = lazy(() => import("./Channel/Chat"));
const MembersChat = lazy(() => import("./Channel/pages/MembersChat"));
const GroupChat = lazy(() => import("./Channel/pages/GroupChat"));
const Files = lazy(() => import("./Channel/pages/Files"));
const Bookmarkpage = lazy(() => import("./Channel/pages/Bookmarkpage"));

// reporting
const Dashboard = lazy(() => import("./Reporting/Dashboard"));
const Employeereport = lazy(() => import("./Reporting/Employeereport"));
const SummaryLanding = lazy(() => import("./Component/SummaryLanding"));
const AttendeeTodaySummary = lazy(() => import("./Reporting/AttendeeTodaySummary"));
const AttendeeSummary = lazy(() => import("./Reporting/AttendeeSummary"));
const TodoLanding = lazy(() => import("./Component/TodoLanding"));
const AttendeeTodo = lazy(() => import("./Reporting/AttendeeTodo"));
const AttendeeTodayTodo = lazy(() => import("./Reporting/AttendeeTodayTodo"));
const Feedback = lazy(() => import("./Reporting/Feedback"));

export const authRoutes = [
  { path: "/", element: Login },
  { path: "/login", element: Login },
  { path: "/create-account", element: Register }
];


export const publicRoutes = [
  { path: "/", element: <Dashboard /> },
  { path: "/profile", element: <Profile /> },
  {
    path: "/channel",
    element: <Chat />,
    children: [
      {
        path: "g/:slug/:id",
        element: <GroupChat />
      },
      {
        path: "m/:slug/:id",
        element: <MembersChat />
      }
    ]
  }
];
export const inhouseRoutes = [
  { path: "/setting", element: <Setting /> },
  { path: "/bookmark", element: <Bookmarkpage /> },
  { path: "/feedback", element: <Feedback /> },
  { path: "/employeereport", element: <Employeereport /> },
  { path: "/profile", element: <Profile /> },
  {
    path: "/summary", 
    element: <SummaryLanding />, 
    children: [
      { path: "todayssummary", element: <AttendeeTodaySummary /> },
      { path: "m/:slug/:id/:name", element: <AttendeeSummary /> }
    ]
  },
  {
    path: "/todo", 
    element: <TodoLanding />, 
    children: [
      { path: "todaystodo", element: <AttendeeTodayTodo /> },
      { path: "m/:slug/:id/:name", element: <AttendeeTodo /> }
    ]
  },
  {
    path: "/channel",
    element: <Chat />,
    children: [
      {
        path: "g/:slug/:id",
        element: <GroupChat />
      },
      {
        path: "m/:slug/:id",
        element: <MembersChat />
      }
    ]
  }
];

export const adminRoutes = [
  // Admin-specific routes can go here
  { path: "/files", element: <Files /> },
];


