import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { postApiCall } from "../Service/Api";
import AuthLayout from "./AuthLayout";
import Loader from "../Component/Loader";

export default function Login({ setToken }) {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [otp, setOtp] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  
  const togglePasswordVisibility = () => {
    setPasswordShown((prev) => !prev);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    let apiEndpoint = 'task-manager/attempt-web-otp';
    let payload = { employee_id: employeeId };

    if (activePage >= 2) {
      apiEndpoint = 'task-manager/verify-web-otp';
      payload = { ...payload, otp, browser_id: localStorage.getItem('firebase-token') };
    }
    if (window.electron) {
      payload['user_login_type']=1;
    }else{
      payload['user_login_type']=2;
    }

    const response = await postApiCall(apiEndpoint, payload);
    
    if (response.status) {
      if (activePage === 2) {
        setToken(response);
        window.location.href = '/';
      } else {
        setActivePage(activePage + 1);
      }
    } 
    setLoading(false);
  };

  const resendOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = { employee_id: employeeId };
    await postApiCall('task-manager/attempt-web-otp', payload);
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className="row justify-content-center my-auto">
        <div className="col-sm-8 col-lg-8 col-xl-8 col-xxl-8">
          <div className="py-md-5 py-4">
            <div className="text-center mb-5">
              <h3>Welcome to TeamTrack</h3>
              <p className="text-muted">
                To get started, please sign in
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              {activePage >= 1 && (
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Email ID / Employee ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Employee ID"
                    onChange={(e) => setEmployeeId(e.target.value)}
                    required
                  />
                  {activePage > 1 && (
                    <p className="text-right">
                      <Link onClick={resendOTP}>Resend OTP</Link>
                    </p>
                  )}
                </div>
              )}
              {activePage >= 2 && (
                <div className="mb-3">
                  <label htmlFor="otp" className="form-label">
                    OTP
                  </label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <input
                      placeholder="OTP"
                      name="otp"
                      type={passwordShown ? "text" : "password"}
                      className="form-control pe-5"
                      id="otp-input"
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      onClick={togglePasswordVisibility}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  </div>
                </div>
              )}
              <div className="text-center mt-4">
                <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                  <span hidden={loading}>{activePage === 2 ? 'Log In' : 'Continue'}</span>
                  <Loader hidden={!loading} />
                </button>
              </div>
            </form>
            <div className="text-center">
              <p>Don't have an account? <Link to="/create-account">Sign up</Link></p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="text-center text-muted p-4">
            <p className="mb-0">
              This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.
            </p>
            <p className="mb-0">
              Copyright {new Date().getFullYear()} © <a href="https://www.devwings.com/">Devwings</a> All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
